import { get, axiosPost, axiosUpdate, axiosDel } from '../service';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API, getAuthHeaders } from '../constants';
import { objectToQueryString } from './useSearch';

const headers = getAuthHeaders();
// Get the count of the saved search result
const getSavedDashboardCount = () => {
  return get(`${API}/dashboard-charts/total_count`, headers);
};

export const useSavedDashboardQueryCount = () => {
  return useQuery({
    queryKey: ['total-saved-dashboards'],
    queryFn: () => getSavedDashboardCount(),
    enabled: true,
    refetchOnWindowFocus: false,
  });
};

// create save dashboard
export const createSaveDashboarsData = (payload) => {
  return axiosPost(`${API}/dashboard-charts`, payload, headers);
};

export const useCreateSaveDashboardData = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createSaveDashboarsData,
    onSuccess: () => {
      queryClient.invalidateQueries(['total-saved-dashboards']);
      queryClient.invalidateQueries(['save-dashboard-data']);
    },
  });
};
// edit saved dashboard
export const createEditDashboardData = (payload) => {
  return axiosUpdate(`${API}/dashboard-charts`, payload, headers);
};

export const useEditSaveDashboardData = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createEditDashboardData,
    onSuccess: () => {},
  });
};

// get all dashboards
const getSavedDashboardQueryData = (filter, dashboardId) => {
  return get(
    `${API}/dashboard-charts?dashboard_id=${dashboardId || ''}&filter_by=${
      filter === 'recent' ? 'all' : filter || ''
    }&limit=${filter === 'recent' ? 8 : ''}`,
    headers
  );
};

export const useGetSavedDashboardQueryData = (
  filter,
  dashboardId,
  enabled = true
) => {
  return useQuery({
    queryKey: ['save-dashboard-data', filter, dashboardId],
    queryFn: () => getSavedDashboardQueryData(filter, dashboardId),
    enabled,
    refetchOnWindowFocus: false,
  });
};

// delete saved dashboard
export const createDeleteDashboardData = (payload) => {
  return axiosDel(`${API}/dashboard-charts`, headers, payload);
};

export const useDeleteSaveDashboardData = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createDeleteDashboardData,
    onSuccess: () => {
      queryClient.invalidateQueries(['save-dashboard-data']);
      queryClient.invalidateQueries(['total-saved-dashboards']);
    },
  });
};

// add dashboard bookmark
export const addDashboardBookmark = (payload) => {
  return axiosPost(`${API}/dashboard-charts/bookmark`, payload, headers);
};

export const useAddDashboardBookmark = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addDashboardBookmark,
    onSuccess: () => {
      queryClient.invalidateQueries(['save-dashboard-data']);
    },
  });
};

// delete dashboard bookmark
export const delDashboardBookmark = (payload) => {
  return axiosDel(`${API}/dashboard-charts/bookmark`, headers, payload);
};

export const useDelDashboardBookmark = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: delDashboardBookmark,
    onSuccess: () => {
      queryClient.invalidateQueries(['save-dashboard-data']);
    },
  });
};

// Recent-Dashboard for Home Page

const getRecentDashboardDataList = () => {
  const authHeaders = getAuthHeaders();
  return get(
    `${API}/dashboard-charts?dashboard_id=&filter_by=all&orderBy=updated_at&limit=10`,
    {
      ...authHeaders,
    }
  );
};

export const useGetRecentDashboardList = () => {
  return useQuery({
    queryKey: ['recent-dashboard-data'],
    queryFn: () => getRecentDashboardDataList(),
    enabled: true,
    refetchOnWindowFocus: false,
  });
};

const shareOneDashboardToOrgUsers = (payload) => {
  const authHeaders = getAuthHeaders();
  return axiosPost(`${API}/dashboard-charts/share`, payload, {
    ...authHeaders,
  });
};

export const useShareOneDashboardToOrgUsers = (payload) => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: shareOneDashboardToOrgUsers,
    onSuccess: () => {
      // queryClient.invalidateQueries(['save-dashboard-data', userId]);
    },
  });
};

const removeOneShareDashboard = (payload) => {
  const authHeaders = getAuthHeaders();
  return axiosDel(
    `${API}/dashboard-charts/share?${objectToQueryString(payload)}`,
    {
      ...authHeaders,
    }
  );
};

export const useRemoveOneShareDashboard = (payload) => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: removeOneShareDashboard,
    onSuccess: () => {
      // queryClient.invalidateQueries(['save-dashboard-data', userId]);
    },
  });
};
