import styled from 'styled-components';

export const ChartToolTipWrp = styled.div`
  max-width: 15rem;
  height: min-content;
  /* padding: 1rem 0 0 0; */
  /* gap: 6px; */
  border-radius: 0.75rem;
  position: absolute;
  top: ${({ componentTop }) => `${componentTop || 9}%`};
  margin-left: ${({ componentLeft, crossIcon, avatarTooltip }) =>
    !crossIcon && !avatarTooltip
      ? `${componentLeft / 2}px`
      : `-${componentLeft / 6}px`};
  z-index: 1;
  background: ${({ theme }) => theme.text};
  display: flex;
  flex-direction: column;
  &::before {
    content: '';
    position: absolute;
    rotate: 270deg;
    top: 0;
    margin-left: ${({
      componentLeft,
      dashboardType,
      crossIcon,
      avatarTooltip,
    }) =>
      !crossIcon
        ? avatarTooltip
          ? `${componentLeft + 2}px`
          : !dashboardType
          ? `${componentLeft / 2 || 2.5}px`
          : `${componentLeft - componentLeft / 2}px `
        : `${componentLeft}px`};
    border-width: 0.5rem; /* Adjust the size of the arrow */
    border-style: solid;
    border-color: transparent transparent transparent #000; /* Arrow color */
    transform: translate(100%, -100%); /* Center the arrow vertically */
  }
`;

export const TooltipIconWrp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
`;

export const ChartText = styled.div`
  width: fit-content;
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: left;
  padding: ${({ crossIcon }) =>
    crossIcon ? '0 0.75rem 0.75rem 0.75rem' : '1rem'};
  color: ${({ theme }) => theme.background};
`;
