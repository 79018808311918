import React from 'react';
// import ailoader from '../../assets/img/ailoader.png';
import aiLoader from '../../assets/icons/loading/aiLoader.gif';
import aiLoader2 from '../../assets/icons/loading/aiLoaderB.gif';
import PropTypes from 'prop-types';
import { AiImg, AiLoading, ContentWrp, UberSubtitle, Dots } from './index.sc';

const AiLoaderV2 = ({ margin = false, blend = false }) => {
  return (
    <AiLoading>
      <ContentWrp margin={margin}>
        <AiImg src={blend ? aiLoader : aiLoader2} alt="loader" />
        <UberSubtitle
          theme="#8A8A8A"
          style={{ marginBottom: blend ? '4rem' : '5rem' }}
        >
          Analyzing
          <Dots delay={0.2} />
          <Dots delay={0.4} />
          <Dots delay={0.6} />
        </UberSubtitle>
      </ContentWrp>
    </AiLoading>
  );
};

AiLoaderV2.propTypes = {
  margin: PropTypes.bool,
  blend: PropTypes.bool,
};
export default AiLoaderV2;
