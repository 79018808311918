import React from 'react';
import { LoaderContainer, Dot } from './index.sc';

const ThreeDotsLoader = () => {
  return (
    <LoaderContainer>
      <Dot delay={0}>•</Dot>
      <Dot delay={0.5}>•</Dot>
      <Dot delay={1}>•</Dot>
    </LoaderContainer>
  );
};

export default ThreeDotsLoader;
