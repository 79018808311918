import React, { useEffect, useRef } from 'react';
import Proptypes from 'prop-types';
import * as d3 from 'd3';
import {
  Bar,
  BarBoxwrpe,
  BarContainer,
  Barwrpr,
  GraphBoxwrpr,
  GridLineLabel,
  GridMainLeft,
  GridMainRight,
  GridMainWrp,
  // GridWrp,
  GridWrpLeft,
  GridWrpRight,
  Gridline,
  LabelWrapper,
  LableSec,
  NoTextDataWrp,
} from './index.sc';
import {
  onClickFunc,
  onMouseEnterMoveLeave,
} from '../../graphs/utils/graphEvents';
import { theme } from '../../constants/theme';

const setData = (tempdata) => {
  const inData = JSON.parse(JSON.stringify(tempdata));
  const labels = inData?.labels
    ? inData?.labels
    : [{ label: 'label', value: 'value' }];
  const data = inData.data;
  const summary = inData.summary;

  const filteredData = data;
  const formattedData = [];

  for (let i = 0; i < filteredData.length; i++) {
    const items = [];
    for (let j = 0; j < labels.length; j++) {
      const value = filteredData[i][labels[j].value];
      const netSentiment = filteredData[i].net_sentiment;
      const totalCount = filteredData[i].total_count - filteredData[i].neutral;
      const item = {
        data: filteredData[i],
        color: filteredData[i].color || labels[j].color,
        index: j,
        labelIndex: i,
        labelText: filteredData[i].label,
        label: labels[j].label,
        value,
        netSentiment,
        totalCount,
        labelColor: filteredData[i]?.labelColor,
      };
      items.push(item);
    }
    formattedData.push(items);
  }
  return { formattedData, summary, labels };
};

const getPercentStr = (max, value, dashboardType) => {
  if (Number.isNaN(max) || Number.isNaN(value)) {
    return '0%';
  }
  let percent;
  if (
    dashboardType &&
    (dashboardType === 'authorimpact' || dashboardType === 'overview')
  ) {
    percent = Math.abs(value);
  } else {
    percent = (value / max) * 100;
  }

  return `${percent}%`;
};

const capitalize = (str) => {
  if (typeof str !== 'string' || str.length === 0) {
    return str;
  }
  // Convert to lowercase first, then capitalize
  return str.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

const HorizontalMultiBar = ({ data, config, dashboardType }) => {
  const preparedData = setData(data);
  const { formattedData } = preparedData;
  const leftValueArr = formattedData.map((item) => item[0]?.value || 0);
  const rightValueArr = formattedData.map((item) => item[1]?.value || 0);
  const leftMax = Math.max(...leftValueArr);
  const rightMax = Math.max(...rightValueArr);
  const barRefs = useRef([]);

  useEffect(() => {
    const parentContainer = d3.select('.graph-box-wrapper');

    // Attach the click event
    // onClickFunc(parentContainer, 'bar', config);

    // Attach the mouse enter, move, and leave events
    onMouseEnterMoveLeave(
      parentContainer,
      'bar',
      config.handleMouseEnter,
      config.handleMouseMove,
      config.handleMouseLeave,
      config.handleOnClick
    );

    return () => {
      parentContainer.selectAll('.bar').on('click', null);
      parentContainer.selectAll('.bar').on('mouseenter', null);
      parentContainer.selectAll('.bar').on('mousemove', null);
      parentContainer.selectAll('.bar').on('mouseleave', null);
    };
  }, [config]);

  const handleValue = (item) => {
    const percentAgeValue = getPercentStr(item.totalCount, item.value)?.replace(
      '%',
      ''
    );
    if (item.label === 'Positive') {
      if (percentAgeValue > 0 && percentAgeValue < 1) {
        return `${Math.ceil(
          parseFloat(getPercentStr(item.totalCount, item.value))
        )}%`;
      } else if (percentAgeValue > 99 && percentAgeValue < 100) {
        return `${Math.round(
          parseInt(getPercentStr(item.totalCount, item.value))
        )}%`;
      }
      return `${Math.ceil(
        parseFloat(getPercentStr(item.totalCount, item.value))
      )}%`;
    } else {
      if (percentAgeValue > 0 && percentAgeValue < 1) {
        return `${Math.ceil(
          parseFloat(getPercentStr(item.totalCount, item.value))
        )}%`;
      } else if (percentAgeValue > 99 && percentAgeValue < 100) {
        return `${Math.round(
          parseInt(getPercentStr(item.totalCount, item.value))
        )}%`;
      }
      return `${Math.round(
        parseInt(getPercentStr(item.totalCount, item.value))
      )}%`;
    }
  };

  return (
    <>
      <GraphBoxwrpr
        type={config?.dashboardType}
        overView={config?.overView}
        className="graph-box-wrapper"
      >
        {formattedData?.length === 0 ||
        formattedData === undefined ||
        formattedData === null ? (
          <NoTextDataWrp>No Data</NoTextDataWrp>
        ) : (
          formattedData.map((ele, i) => (
            <BarContainer
              key={i}
              id={`bar-container-${i}`}
              type={config?.dashboardType}
              overView={config?.overView}
            >
              <LabelWrapper
                authorChart={
                  dashboardType &&
                  (dashboardType === 'authorimpact' ||
                    dashboardType === 'overview')
                }
              >
                {capitalize(ele[0]?.labelText) || ''}
              </LabelWrapper>

              <BarBoxwrpe config={config}>
                {ele.reverse().map((item, j) => (
                  <Barwrpr
                    key={`${i}${j}`}
                    first={j === 0}
                    last={j === ele.length - 1}
                  >
                    {!dashboardType &&
                      (item.label === 'Positive' ? (
                        <>
                          <Bar
                            className="bar"
                            positive={true}
                            ref={(el) => (barRefs.current[i] = el)}
                            width={getPercentStr(item.totalCount, item.value)}
                            color={item.color}
                            gradientColor={theme?.light?.graphColors?.green40}
                            onClick={(e) => config.handleOnClick(e, item)}
                            onMouseEnter={(e) =>
                              config.handleMouseEnter(e, item, true)
                            }
                            onMouseMove={(e) =>
                              config.handleMouseEnter(e, item, true)
                            }
                            onMouseLeave={(e) =>
                              config.handleMouseEnter(e, item, true)
                            }
                          />
                          <LableSec style={{ justifyContent: 'flex-end' }}>
                            <span>
                              {item.value === 0 ? '' : handleValue(item)}
                            </span>
                          </LableSec>
                        </>
                      ) : (
                        <>
                          <LableSec style={{ justifyContent: 'flex-start' }}>
                            <span>
                              {item.value === 0 ? '' : handleValue(item)}
                            </span>
                          </LableSec>
                          <Bar
                            className="bar"
                            ref={(el) => (barRefs.current[i] = el)}
                            width={getPercentStr(item.totalCount, item.value)}
                            color={item.color}
                            gradientColor={theme?.light?.graphColors?.red40}
                            positive={false}
                            onClick={(e) => config.handleOnClick(e, item)}
                            onMouseEnter={(e) =>
                              config.handleMouseEnter(e, item, false)
                            }
                            onMouseMove={(e) =>
                              config.handleMouseEnter(e, item, false)
                            }
                            onMouseLeave={(e) =>
                              config.handleMouseEnter(e, item, false)
                            }
                          />
                        </>
                      ))}

                    {/* author impact */}

                    {dashboardType &&
                      (dashboardType === 'authorimpact' ||
                        dashboardType === 'overview') &&
                      (item.label === 'Positive' ? (
                        <>
                          <Bar
                            className="bar"
                            ref={(el) => (barRefs.current[i] = el)}
                            width={getPercentStr(
                              leftMax,
                              item.netSentiment > 0 ? item.netSentiment : '',
                              dashboardType
                            )}
                            color={item.color}
                            gradientColor={theme?.light?.graphColors?.green40}
                            onClick={(e) => config.handleOnClick(e, item)}
                            onMouseEnter={(e) =>
                              config.handleMouseEnter(e, item)
                            }
                            onMouseMove={(e) =>
                              config.handleMouseEnter(e, item)
                            }
                            onMouseLeave={(e) =>
                              config.handleMouseEnter(e, item)
                            }
                          />
                          <LableSec style={{ justifyContent: 'flex-end' }}>
                            <span>
                              {item.netSentiment === 0
                                ? ''
                                : item.netSentiment > 0
                                ? `${
                                    item.netSentiment === 100
                                      ? item.netSentiment
                                      : item.netSentiment > 0 &&
                                        item.netSentiment < 1
                                      ? item.netSentiment.toFixed(1)
                                      : item.netSentiment.toFixed(0)
                                  }%`
                                : ''}
                            </span>
                          </LableSec>
                        </>
                      ) : (
                        <>
                          <LableSec style={{ justifyContent: 'flex-start' }}>
                            <span>
                              {item.netSentiment === 0
                                ? ''
                                : item.netSentiment < 0
                                ? `${
                                    item.netSentiment === 100
                                      ? item.netSentiment
                                      : item.netSentiment > 0 &&
                                        item.netSentiment < 1
                                      ? item.netSentiment.toFixed(1)
                                      : item.netSentiment.toFixed(0)
                                  }%`
                                : ''}
                            </span>
                          </LableSec>
                          <Bar
                            className="bar"
                            ref={(el) => (barRefs.current[i] = el)}
                            width={getPercentStr(
                              rightMax,
                              item.netSentiment < 0 ? item.netSentiment : '',
                              dashboardType
                            )}
                            color={item.color}
                            gradientColor={theme?.light?.graphColors?.red40}
                            onClick={(e) => config.handleOnClick(e, item)}
                            onMouseEnter={(e) =>
                              config.handleMouseEnter(e, item)
                            }
                            onMouseMove={(e) =>
                              config.handleMouseEnter(e, item)
                            }
                            onMouseLeave={(e) =>
                              config.handleMouseEnter(e, item)
                            }
                          />
                        </>
                      ))}
                  </Barwrpr>
                ))}
              </BarBoxwrpe>
            </BarContainer>
          ))
        )}
      </GraphBoxwrpr>
      {formattedData?.length > 0 && (
        <GridMainWrp>
          <GridMainLeft></GridMainLeft>
          <GridMainRight>
            <GridWrpLeft>
              {!dashboardType === 'authorimpact' &&
                [...Array(5)].map((ele, i) => (
                  <>
                    <Gridline
                      key={`line-key-${i}`}
                      style={{
                        right: `${i * 20}%`,
                        display: i === 0 ? 'none' : 'block',
                      }}
                    />
                    <GridLineLabel
                      style={{ right: `calc(${i * 20}% - 1.5rem)` }}
                    >
                      {i === 0 ? `${i * 20}%` : `${i * 20}%`}
                    </GridLineLabel>
                  </>
                ))}
            </GridWrpLeft>
            <GridWrpRight>
              {[...Array(5)].map((ele, i) => (
                <>
                  <Gridline
                    key={`line-key-${i}`}
                    style={{
                      left: `${
                        !dashboardType === 'authorimpact' ? i * 20 : 0
                      }%`,
                      display:
                        !dashboardType === 'authorimpact' ? 'none' : 'block',
                    }}
                  />
                  {!dashboardType === 'authorimpact' && (
                    <GridLineLabel
                      style={{ left: `calc(${i * 20}% - 1.5rem)` }}
                    >
                      {i === 0 ? '' : `${i * 20}%`}
                    </GridLineLabel>
                  )}
                </>
              ))}
            </GridWrpRight>
          </GridMainRight>
        </GridMainWrp>
      )}
    </>
  );
};

HorizontalMultiBar.propTypes = {
  data: Proptypes.object,
  config: Proptypes.object,
  dashboardType: Proptypes.string,
};

export default HorizontalMultiBar;
