import React from 'react';
import { ChartText, ChartToolTipWrp, TooltipIconWrp } from './index.sc';
import Proptypes from 'prop-types';
import Close from '../../assets/icons/Close';
import { mailSVG } from '../article-graphs';

const ChartToolTip = ({
  text,
  dashboardType,
  componentTop,
  componentLeft,
  crossIcon,
  setTooltipFlag,
  avatarTooltip = false,
}) => {
  return (
    <ChartToolTipWrp
      dashboardType={dashboardType}
      componentTop={componentTop}
      componentLeft={componentLeft}
      crossIcon={crossIcon}
      avatarTooltip={avatarTooltip}
    >
      {crossIcon && (
        <TooltipIconWrp>
          <div>{mailSVG}</div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => setTooltipFlag(false)}
          >
            <Close width="1.25rem" height="1.25rem" />
          </div>
        </TooltipIconWrp>
      )}
      <ChartText crossIcon={crossIcon}>{text}</ChartText>
    </ChartToolTipWrp>
  );
};

export default ChartToolTip;

ChartToolTip.propTypes = {
  text: Proptypes.string,
  dashboardType: Proptypes.bool,
  componentTop: Proptypes.string,
  componentLeft: Proptypes.string,
  crossIcon: Proptypes.bool,
  avatarTooltip: Proptypes.bool,
  setTooltipFlag: Proptypes.func,
};
