import styled, { css, keyframes } from 'styled-components/macro';

export const PopupBackWrapper = styled.div`
  /* top: 0;
  left: 0; */
  overflow-y: scroll;
  background: ${({ position }) => (position ? '' : 'rgba(160, 167, 198, 0.6)')};
  width: 100vw;
  height: 100vh;
  position: fixed;
  transition: 0.3s ease-in-out;
  z-index: ${({ zIndex }) => zIndex};
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ${({ position }) =>
    position === 'left'
      ? css`
          animation: ${slideOutAnimation} 600ms ease;
          top: 0;
          left: -5rem;
          flex-direction: row-reverse;
        `
      : position === 'right'
      ? css`
          animation: ${slideAnimation} 600ms ease;
          top: 0;
          right: -5rem;
          flex-direction: row;
        `
      : css`
          /* transition: opacity 400ms ease-in, transform 10ms 400ms ease-in,
            flex-direction 10ms 400ms ease-in, right 10ms 400ms ease-in;
          opacity: 0;
          right: 100%;
          transform: translateX(-100%);
          flex-direction: row; */
          top: 0;
          left: 0;
        `}
`;

export const PopupContainer = styled.div`
  width: ${({ width = '75vw' }) => width};
  max-height: ${({ height }) => height || '90vh'};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: ${(props) =>
    props.open ? 'translate(-50%, -50%)' : 'translate(-50%,0)'};
  box-sizing: border-box;
  transition: 0.3s ease-in-out;
  background: ${({ background }) => background || '#ffffff'};
  box-shadow: rgba(00, 00, 00, 0.2) 0px 12px 32px;
  border-radius: ${(props) => props.borderRadius};
  padding: ${(props) => props.padding};
  gap: 10px;
  z-index: 1000;
  max-width: 800px;
`;

export const Back = styled.div`
  box-sizing: border-box;
  height: 400px;
  border: 2px solid black;
  width: 100%;
  background: #542354;
  display: flex;
  align-items: center;
`;

export const XCircle = styled.div`
  position: absolute;
  top: 14px;
  right: 20px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

// Overview Content styling

export const ContentWrpr = styled.div`
  width: 51rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const TitlesBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Titlewrpr = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: #000000;
`;

export const Descriptionwrpr = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #585858;
`;

export const Labelwrpr = styled.label`
  box-sizing: border-box;
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  background: #f6f7fb;
  border: 1px solid #c3c7d9;
  border-radius: 10px;
`;

export const Inputwrpr = styled.input`
  border: none;
  outline: none;
  background: #f6f7fb;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  padding-top: 3px;
  width: 100%;
  &:focus {
    background: #f6f7fb;
  }
  &::placeholder {
    font-weight: 500;
    font-size: 15px;
    color: #999999;
    display: flex;
    align-items: flex-end;
  }
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 0.87rem;
  color: #000000;
`;

export const Line = styled.div`
  width: 100%;
  opacity: 0.5;
  border: 0.5px solid #c3c7d9;
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const BoxTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #000000;
`;

export const CheckBoxwrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  box-sizing: border-box;
  width: 1rem;
  height: 1rem;
  background-color: #ffffff;
  &:checked {
    width: 1rem;
    height: 1rem;
    accent-color: #675ef2;
    border-radius: 3px;
  }
`;

export const CheckBoxtxt = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #000000;
`;

export const ButtonContainer = styled.div`
  height: 3.4rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 0.6rem;
  border-top: 1.2px solid #c3c7d9;
`;

export const PopButton = styled.div`
  box-sizing: border-box;
  padding: 9px 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: ${(props) => props.background};
  border-radius: 10px;
  gap: 0.6rem;
  cursor: pointer;
`;

export const ButtonTxt = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: -0.02em;
  color: #ffffff;
`;

const slideAnimation = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;
const slideOutAnimation = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
`;
