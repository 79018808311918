import React from 'react';
import AllMatchIcon from '../assets/icons/AllMatchIcon';
import AttachMoneyIcon from '../assets/icons/AttachMoneyIcon';
import DynamicFeedIcon from '../assets/icons/DynamicFeedIcon';
import TableChartViewIcon from '../assets/icons/TableChartViewIcon';
import { format, parse } from 'date-fns';
import { theme } from './theme';
import { getTokenData } from './validateToken';
import { logAwsRumEvent } from '../utils';
import * as Sentry from '@sentry/react';

// export const baseURL = ;
export const API = process.env.REACT_APP_API;
export const baseURL = process.env.REACT_APP_BACKEND_DUMMY;
export const tokenKey = 'AUTH_KEY';
export const refreshKey = 'REFRESH_KEY';
export const loginTypeLogin = 'login';
export const loginTypeCreatePassword = 'create-password';
export const forgot = 'forgot';
export const otpTypechangePassword = 'change_password';
export const sentiment = 'sentiment';
export const volume = 'volume';
export const noDataForDashboard = 'noDataForDashboard';
export const sseEndpoint = null;
export const socketEndpoint = process.env.REACT_APP_WEBSOCKET_API;
export const prImpactWSEndpoint = process.env.REACT_APP_PR_WEBSOCKET;

export const admin = 'Admin';
export const analyst = 'Analyst';
export const reader = 'Reader';

export const chunkLoadError = 'ChunkLoadError';

export const access = {
  fullAccess: 'Full Access',
  canEdit: 'Can Edit',
  canView: 'Can View',
};

export const accessLabels = [
  { label: 'Full Access', value: 'full access' },
  { label: 'Can Edit', value: 'can edit' },
  { label: 'Can View', value: 'can view' },
];

// AWS CLOUDWATCH RUM CONFIG
export const awsIdentityPoolId = process.env.REACT_APP_AWS_IDENTITY_POOL_ID;
export const awsEndPoint = process.env.REACT_APP_AWS_END_POINT;
export const awsApplicationId = process.env.REACT_APP_APPLICATION_ID;
export const awsApplicationVersion = process.env.REACT_APP_APPLICATION_VERSION;
export const awsRegion = process.env.REACT_APP_REGION;

export const setAuthenticationTokens = (tokenInfo) => {
  localStorage.setItem(tokenKey, tokenInfo?.access);
  localStorage.setItem(refreshKey, tokenInfo?.refresh);
};

export const getAuthHeaders = () => {
  const authToken = localStorage.getItem(tokenKey);
  if (authToken) {
    return {
      Authorization: 'Bearer ' + authToken,
    };
  }
  return {};
};

export const dummyData = [
  {
    author: 'Chinua Achebe',
    country: 'Nigeria',
    imageLink: 'images/things-fall-apart.jpg',
    language: 'English',
    link: 'https://en.wikipedia.org/wiki/Things_Fall_Apart\n',
    pages: 209,
    title: 'Things Fall Apart',
    value: 'Things Fall Apart',
    year: 1958,
  },
  {
    author: 'Hans Christian Andersen',
    country: 'Denmark',
    imageLink: 'images/fairy-tales.jpg',
    language: 'Danish',
    link: 'https://en.wikipedia.org/wiki/Fairy_Tales_Told_for_Children._First_Collection.\n',
    pages: 784,
    title: 'Fairy tales',
    value: 'Fairy tales',
    year: 1836,
  },
  {
    author: 'Dante Alighieri',
    country: 'Italy',
    imageLink: 'images/the-divine-comedy.jpg',
    language: 'Italian',
    link: 'https://en.wikipedia.org/wiki/Divine_Comedy\n',
    pages: 928,
    title: 'The Divine Comedy',
    value: 'The Divine Comedy',
    year: 1315,
  },
  {
    author: 'Unknown',
    country: 'Sumer and Akkadian Empire',
    imageLink: 'images/the-epic-of-gilgamesh.jpg',
    language: 'Akkadian',
    link: 'https://en.wikipedia.org/wiki/Epic_of_Gilgamesh\n',
    pages: 160,
    title: 'The Epic Of Gilgamesh',
    value: 'The Epic Of Gilgamesh',
    year: -1700,
  },
  {
    author: 'Unknown',
    country: 'Achaemenid Empire',
    imageLink: 'images/the-book-of-job.jpg',
    language: 'Hebrew',
    link: 'https://en.wikipedia.org/wiki/Book_of_Job\n',
    pages: 176,
    title: 'The Book Of Job',
    value: 'The Book Of Job',
    year: -600,
  },
  {
    author: 'Unknown',
    country: 'India/Iran/Iraq/Egypt/Tajikistan',
    imageLink: 'images/one-thousand-and-one-nights.jpg',
    language: 'Arabic',
    link: 'https://en.wikipedia.org/wiki/One_Thousand_and_One_Nights\n',
    pages: 288,
    title: 'One Thousand and One Nights',
    value: 'One Thousand and One Nights',
    year: 1200,
  },
  {
    author: 'Unknown',
    country: 'Iceland',
    imageLink: 'images/njals-saga.jpg',
    language: 'Old Norse',
    link: 'https://en.wikipedia.org/wiki/Nj%C3%A1ls_saga\n',
    pages: 384,
    title: "Nj\u00e1l's Saga",
    value: "Nj\u00e1l's Saga",
    year: 1350,
  },
  {
    author: 'Jane Austen',
    country: 'United Kingdom',
    imageLink: 'images/pride-and-prejudice.jpg',
    language: 'English',
    link: 'https://en.wikipedia.org/wiki/Pride_and_Prejudice\n',
    pages: 226,
    title: 'Pride and Prejudice',
    value: 'Pride and Prejudice',
    year: 1813,
  },
  {
    author: 'Honor\u00e9 de Balzac',
    country: 'France',
    imageLink: 'images/le-pere-goriot.jpg',
    language: 'French',
    link: 'https://en.wikipedia.org/wiki/Le_P%C3%A8re_Goriot\n',
    pages: 443,
    title: 'Le P\u00e8re Goriot',
    value: 'Le P\u00e8re Goriot',
    year: 1835,
  },
  {
    author: 'Samuel Beckett',
    country: 'Republic of Ireland',
    imageLink: 'images/molloy-malone-dies-the-unnamable.jpg',
    language: 'French, English',
    link: 'https://en.wikipedia.org/wiki/Molloy_(novel)\n',
    pages: 256,
    title: 'Molloy, Malone Dies, The Unnamable, the trilogy',
    value: 'Molloy, Malone Dies, The Unnamable, the trilogy',
    year: 1952,
  },
];

export const roles = [
  {
    id: 4,
    access_type: 'super_admin',
  },
  {
    id: 5,
    access_type: 'admin',
  },
  {
    id: 6,
    access_type: 'maintainer',
  },
  {
    id: 6,
    access_type: 'user',
  },
];

const returnBoolean = () => {
  return true;
};

const returnTraditional = (ele) => {
  return ele?.newsType !== 'X (Twitter)' && ele?.newsType !== 'Reddit';
};

const returnTwitter = (ele) => {
  return ele?.newsType === 'X (Twitter)';
};

const returnTwitterOrReddit = (ele) => {
  return ele?.newsType === 'X (Twitter)' || ele?.newsType === 'Reddit';
};

export const bottomDetails = [
  { label: 'Sentiment', value: 'sentiment', icon: '', show: returnBoolean },
  { label: 'Potential Reach', value: 'reach', show: returnTwitterOrReddit },
  { label: 'Engagement', value: 'engagement_score', show: returnTwitter },
  {
    label: 'Reach',
    value: 'reach',
    icon: <TableChartViewIcon />,
    show: returnTraditional,
  },
  {
    label: 'AVE',
    value: 'ave',
    icon: <AttachMoneyIcon />,
    show: returnTraditional,
  },
  {
    label: 'Keyword matches',
    value: 'matches',
    icon: <AllMatchIcon />,
    show: returnBoolean,
  },
  {
    label: 'Retweet',
    value: 'retweet_count',
    show: returnTwitter,
  },
  {
    label: 'Syndication',
    value: 'syndication',
    icon: <DynamicFeedIcon />,
    show: returnTraditional,
  },
];

export const Bottomkeys = [
  // { label: 'Link', value: 'link' },
  { label: 'Publication', value: 'publication_url' },
  {
    label: '12 Jun 2023',
    value: 'date',
    func: (ele) => {
      const parsedDate =
        ele?.date?.split(' ')?.length >= 2
          ? parse(ele.date.split(' ')[0], 'yyyy-MM-dd', new Date())
          : parse(ele.date.split('T')[0], 'yyyy-MM-dd', new Date());
      const formattedDate = format(parsedDate, 'MMM dd, yyyy');
      return formattedDate;
    },
  },
  { label: 'Online News', value: 'newsType' },
  {
    label: 'New York, US',
    value: 'place',
    func: (ele) => {
      return ele.place?.toUpperCase();
    },
  },
];

export const getParsedDate = (date, currentFormat, expectedFormat) => {
  try {
    const parsedDate = parse(date, currentFormat, new Date());
    const formattedDate = format(parsedDate, expectedFormat);
    return formattedDate;
  } catch (error) {
    console.log(error);
    const tokenData = getTokenData();
    logAwsRumEvent(eventNames.function_error_handled_log, {
      error: error?.stack,
      type: 'crash detected',
      id: tokenData?.user_id || 'not logged in',
      name: error.name,
    });
    Sentry.captureException(error);

    return '';
  }
};

// cloudwatch custom event names
export const eventNames = {
  api_response_duration: 'api_response_duration',
  api_error_response_duration: 'api_error_response_duration',
  search_filter_res_log: 'search_filter_res_log',
  search_filter_req_log: 'search_filter_req_log',
  search_filter_error_log: 'search_filter_error_log',
  search_source_res_log: 'search_source_res_log',
  search_source_req_log: 'search_source_req_log',
  search_source_error_log: 'search_source_error_log',
  search_websocket_res_log: 'search_websocket_res_log',
  search_websocket_req_log: 'search_websocket_req_log',
  search_websocket_query_duration_log: 'search_websocket_query_duration_log',
  search_websocket_filter_change_log: 'search_websocket_filter_change_log',
  search_websocket_error_log: 'search_websocket_error_log',
  function_error_handled_log: 'function_error_handled_log',
  function_error_unhandled_log: 'function_error_unhandled_log',
};
