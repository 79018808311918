import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  imageURL: '',
};

const imageUrlSlice = createSlice({
  name: 'imageURL',
  initialState,
  reducers: {
    setURL: (state, action) => {
      state.imageURL = action.payload;
    },
  },
});

export const { setURL } = imageUrlSlice.actions;
export default imageUrlSlice.reducer;
