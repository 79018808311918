import styled from 'styled-components';

export const DownloadWrp = styled.div`
  width: 20rem;
  height: 17rem;
  padding: 2rem;
  border-radius: 1rem;
  gap: 2rem;
  display: flex;
  flex-direction: column;
`;

export const DownloadTitle = styled.div`
  font-family: Inter;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: -0.02em;
  text-align: left;
`;

export const DownloadDescp = styled.div`
  width: 20rem;
  height: 4rem;
  gap: 12px;
  font-family: Inter;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.3rem;
  letter-spacing: -0.01em;
  text-align: left;
`;

export const DownloadBtnWrp = styled.div`
  width: 21.7rem;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  justify-content: space-between;
`;
