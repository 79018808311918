import PropTypes from 'prop-types';
import React from 'react';
import * as Styles from './index.sc';

const ToggleSwitch = ({
  name,
  checked,
  onChange,
  small,
  disabled,
  accentColor,
  offAccentColor,
  disabledColor,
  switchColor,
  tempWidth = '',
  tempHeight = '',
  toggleWidth = '',
  toggleHeight = '',
}) => {
  const theme = {};
  const handleKeyPress = (e) => {
    if (e.keyCode !== 32 || disabled) return;
    e.preventDefault();
    onChange({ name, checked: !checked });
  };

  return (
    <Styles.Container
      className="switch-container hide-download-none"
      small={small}
      tempHeight={tempHeight}
      tempWidth={tempWidth}
    >
      <Styles.SwitchWrap
        tabIndex={disabled ? -1 : 1}
        onKeyDown={handleKeyPress}
        small={small}
        checked={checked}
        color={accentColor || theme.main}
        offColor={offAccentColor || '#a1a5b7'}
        disabled={disabled}
        disabledColor={disabledColor || '#d9d9d9'}
        onClick={() => !disabled && onChange({ name, checked: !checked })}
      >
        <Styles.Switch
          small={small}
          checked={checked}
          disabled={disabled}
          switchColor={switchColor}
          toggleHeight={toggleHeight}
          toggleWidth={toggleWidth}
          className="hide-download-none"
        />
      </Styles.SwitchWrap>
    </Styles.Container>
  );
};

export default ToggleSwitch;

ToggleSwitch.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  accentColor: PropTypes.string,
  offAccentColor: PropTypes.string,
  disabledColor: PropTypes.string,
  switchColor: PropTypes.string,
  tempHeight: PropTypes.string,
  tempWidth: PropTypes.string,
  toggleHeight: PropTypes.string,
  toggleWidth: PropTypes.string,
};
