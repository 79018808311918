import React from 'react';

const HelpIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#BFC5CE"
        d="M8 13a.964.964 0 00.708-.292A.964.964 0 009 12a.964.964 0 00-.292-.708A.964.964 0 008 11a.964.964 0 00-.708.292A.964.964 0 007 12c0 .278.097.514.292.708.194.195.43.292.708.292zm-.75-3.188h1.52c0-.513.046-.878.136-1.093.09-.216.31-.49.656-.823.487-.472.823-.875 1.01-1.208.188-.334.282-.702.282-1.105 0-.764-.26-1.385-.781-1.864C9.553 3.239 8.889 3 8.083 3c-.708 0-1.323.188-1.843.563-.521.374-.886.881-1.094 1.52l1.354.563c.125-.39.323-.691.594-.906.27-.216.587-.323.948-.323.389 0 .708.11.958.333.25.222.375.514.375.875 0 .32-.108.604-.323.854a7.88 7.88 0 01-.719.73c-.486.444-.788.808-.906 1.093-.118.285-.177.788-.177 1.51zM8 16a7.796 7.796 0 01-3.104-.625 8.064 8.064 0 01-2.552-1.719 8.063 8.063 0 01-1.719-2.552A7.797 7.797 0 010 8c0-1.111.208-2.15.625-3.115a8.064 8.064 0 014.27-4.26A7.797 7.797 0 018 0c1.111 0 2.15.208 3.115.625a8.095 8.095 0 014.26 4.26C15.792 5.851 16 6.89 16 8a7.796 7.796 0 01-.625 3.104 8.063 8.063 0 01-4.26 4.271A7.774 7.774 0 018 16z"
      ></path>
    </svg>
  );
};

export default HelpIcon;
