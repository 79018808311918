import React from 'react';
import Proptypes from 'prop-types';

const AiIcon = ({
  height = '17',
  width = '16',
  color = '#D83666',
  fill = '#D83665',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 17"
    >
      <g fill={fill || color} clipPath="url(#clip0_39_39853)">
        <path
          fillRule="evenodd"
          d="M18.616 19.327H-2.616A11.998 11.998 0 018 12.927c4.605 0 8.604 2.594 10.616 6.4z"
          clipRule="evenodd"
        ></path>
        <path d="M12 .926l.127.518a2.4 2.4 0 001.755 1.755l.518.127-.518.128a2.4 2.4 0 00-1.755 1.755L12 5.726l-.128-.517a2.4 2.4 0 00-1.755-1.755L9.6 3.326l.517-.127a2.4 2.4 0 001.755-1.755L12 .926zM5.6 2.526l.444 1.801A2.4 2.4 0 007.8 6.082l1.8.444-1.8.445a2.4 2.4 0 00-1.755 1.755l-.444 1.8-.445-1.8A2.4 2.4 0 003.4 6.97l-1.8-.445 1.8-.444a2.4 2.4 0 001.755-1.755l.445-1.8z"></path>
      </g>
      <defs>
        <clipPath id="clip0_39_39853">
          <path
            fill="#fff"
            d="M0 0H16V16H0z"
            transform="translate(0 .126)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default AiIcon;

AiIcon.propTypes = {
  height: Proptypes.string,
  width: Proptypes.string,
  fill: Proptypes.string,
  color: Proptypes.string,
};
