import styled from 'styled-components';

const SlotBodyHeight = 'calc(100% - 2rem)';

export const FullSlot = styled.div`
  width: 100%;
  height: 36rem;
  border-radius: ${({ theme }) => theme.primaryBorderRadius};
  padding: 1.5rem;
  background-color: #ffffff;
  cursor: pointer;
  margin-bottom: 64px;
  border: 2px solid ${({ selected }) => (selected ? 'blue' : 'none')};
  z-index: ${({ selected }) => (selected ? 1 : null)};
`;

export const SlotDetailsMainWrp = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 0.5rem;
  box-sizing: border-box;
`;
export const IconBox = styled.div`
  cursor: auto;
  position: absolute;
  /* width: 5.75rem; */
  display: flex;
  z-index: 1;
  justify-content: space-between;
  align-items: start;
  top: 0rem;
  right: 0;
  gap: 0.5rem;
`;
export const Iconwpr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ width = '2.25rem', height = '2.25rem' }) => `
  height: ${height};
  width: ${width};
  `}
  cursor: pointer;
  border-radius: 0.25rem;
  position: relative;
`;
export const SlotDetailsWrp = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;
export const SlotHeader = styled.div`
  height: 2rem;
  display: flex;
  justify-content: space-between;
`;

export const SlotHeaderLeft = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

export const SlotLeftHeaderTxtWrp = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SlotTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const SlotSubTitle = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme?.graphColors?.coolGray60};
  font-weight: 700;
  font-size: 0.8125rem;
  line-height: 1rem;
  letter-spacing: -1%;
`;
export const SlotBody = styled.div`
  position: relative;
  height: ${SlotBodyHeight};
  width: 100%;
  &.legend,
  &.commentary {
    height: calc(${SlotBodyHeight} - 2rem);
  }

  .selected {
    transition: all 400ms ease;
    opacity: 1 !important;
  }

  .unselected {
    transition: all 400ms ease;
    opacity: 0.2 !important;
  }

  .hover-selected {
    transition: all 400ms ease;
    opacity: 1 !important;
  }
  .hover-unselected {
    transition: all 400ms ease;
    opacity: 0.2 !important;
  }
  .word-cloud-text {
    font-weight: 700;
  }
  display: flex;
  flex-direction: row;
  padding: 2rem 1rem;
`;
export const SlotBodyHeader = styled.div`
  width: 15%;
  /* height: 8rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const SlotBodyMain = styled.div`
  width: 100%;
  height: calc(100%);
`;
export const SlotFooter = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  background-color: ${({ theme }) => theme.background};
  &.one-d {
    padding: 0.7rem 0.875rem;
  }
  &.two-d {
    width: fit-content;
    min-width: 9.625rem;
    height: fit-content;
    flex-shrink: 0;
    padding: 1rem 1rem;
  }
  &.span {
    margin: 0;
    padding: 0;
    font-size: 0.7rem;
  }
`;

export const LegendLabel = styled.div`
  color: #585858;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const LegendValue = styled.div`
  color: #000;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const TooltipLegendContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;

export const PRNoDataTxt = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.disabledBtnColor};
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center !important;
`;
