import React from 'react';
import PropTypes from 'prop-types';

const Overview = ({ color = '#1C1B1F' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="17"
      fill="none"
      viewBox="0 0 19 17"
    >
      <path
        fill={color}
        d="M5.167 16.333L4 15.167l5.75-5.771 2.917 2.916L16.98 8l1.187 1.188-5.5 5.479L9.75 11.75l-4.583 4.583zM2.334 15.5c-.459 0-.851-.163-1.177-.49a1.605 1.605 0 01-.49-1.177V2.167c0-.459.163-.851.49-1.177C1.483.663 1.875.5 2.334.5H14c.459 0 .851.163 1.177.49.327.326.49.718.49 1.177v3.5H2.334V15.5zm0-11.5H14V2.167H2.334V4z"
      ></path>
    </svg>
  );
};

export default Overview;

Overview.propTypes = {
  color: PropTypes.string,
};
