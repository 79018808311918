import styled from 'styled-components';

export const LegendWrp = styled.div`
  width: fit-content;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;
export const LegendContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`;
export const LegendBox = styled.div`
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1rem;
  background-color: ${({ bgColor }) => bgColor || '#c3c7d9'};
  opacity: ${({ opacity }) => 1 / (opacity + 0.4)};
`;
export const LegendLabel = styled.div`
  color: #5c5e60;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 0.5rem; /* 80% */
  letter-spacing: -0.0125rem;
  white-space: nowrap;
  text-transform: capitalize;
`;
