import Markdown from 'react-markdown';
import styled from 'styled-components';

export const PopupTxtWpr = styled.div`
  min-height: 5rem;
  padding: 2rem;
  height: 65vh;
  overflow-y: auto;
`;

export const PopupStyledMarkdown = styled(Markdown)`
  font-family: Inter;
  font-size: ${({ articleFont }) => articleFont || '0.8125rem'};
  font-weight: ${({ weight }) => weight || '500'};
  line-height: ${({ lineHeight }) => lineHeight || '160%'};
  text-align: left;
  color: ${({ color }) => color || '#585858'};
`;
