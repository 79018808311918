export const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // Get the current retry count from session storage
    const retryCount = parseInt(
      window.sessionStorage.getItem('retry-lazy-count') || '0',
      10
    );

    // Check if we've reached the maximum retry count
    if (retryCount >= 5) {
      return reject(new Error('Maximum retry attempts reached'));
    }

    // Try to import the component
    componentImport()
      .then((component) => {
        // Success, so reset the retry count
        window.sessionStorage.setItem('retry-lazy-count', '0');
        resolve(component);
      })
      .catch((error) => {
        // Increment the retry count
        const newRetryCount = retryCount + 1;
        window.sessionStorage.setItem(
          'retry-lazy-count',
          newRetryCount.toString()
        );

        if (newRetryCount <= 5) {
          // We haven't reached the max retries yet, so refresh the page
          window.location.reload();
        } else {
          // We've reached the maximum retries
          reject(error);
        }
      });
  });
};
