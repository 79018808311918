import React from 'react';
import { LoaderWrp } from './index.sc';

const Loader = () => {
  return (
    <LoaderWrp>
      {' '}
      <p
        style={{
          margin: '0px',
          marginBottom: '3px',
          marginRight: '3px',
          color: '#5F39F8',
        }}
      >
        Loading
      </p>
      <svg
        width="24"
        height="6"
        viewBox="0 0 24 6"
        xmlns="http://www.w3.org/2000/svg"
        fill="#5F39F8"
      >
        <circle cx="3" cy="3" r="3">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1.5s"
            repeatCount="indefinite"
            begin="0s"
          />
        </circle>
        <circle cx="12" cy="3" r="3">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1.5s"
            repeatCount="indefinite"
            begin="0.5s"
          />
        </circle>
        <circle cx="21" cy="3" r="3">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="1.5s"
            repeatCount="indefinite"
            begin="1s"
          />
        </circle>
      </svg>
    </LoaderWrp>
  );
};

export default Loader;
