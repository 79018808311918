export const StatesWithMapData = [
  {
    state: 'Maine',
    label: '23',
    short: 'ME',
  },
  {
    state: 'Massachusetts',
    label: '25',
    short: 'MA',
  },
  {
    state: 'Michigan',
    label: '26',
    short: 'MI',
  },
  {
    state: 'Montana',
    label: '30',
    short: 'MT',
  },
  {
    state: 'Nevada',
    label: '32',
    short: 'NV',
  },
  {
    state: 'New Jersey',
    label: '34',
    short: 'NJ',
  },
  {
    state: 'New York',
    label: '36',
    short: 'NY',
  },
  {
    state: 'North Carolina',
    label: '37',
    short: 'NC',
  },
  {
    state: 'Ohio',
    label: '39',
    short: 'OH',
  },
  {
    state: 'Pennsylvania',
    label: '42',
    short: 'PA',
  },
  {
    state: 'Rhode Island',
    label: '44',
    short: 'RI',
  },
  {
    state: 'Tennessee',
    label: '47',
    short: 'TN',
  },
  {
    state: 'Texas',
    label: '48',
    short: 'TX',
  },
  {
    state: 'Utah',
    label: '49',
    short: 'UT',
  },
  {
    state: 'Washington',
    label: '53',
    short: 'WA',
  },
  {
    state: 'Wisconsin',
    label: '55',
    short: 'WI',
  },
  {
    state: 'Puerto Rico',
    label: '72',
    short: 'PR',
  },
  {
    state: 'Maryland',
    label: '24',
    short: 'MD',
  },
  {
    state: 'Alabama',
    label: '01',
    short: 'AL',
  },
  {
    state: 'Alaska',
    label: '02',
    short: 'AK',
  },
  {
    state: 'Arizona',
    label: '04',
    short: 'AZ',
  },
  {
    state: 'Arkansas',
    label: '05',
    short: 'AR',
  },
  {
    state: 'California',
    label: '06',
    short: 'CA',
  },
  {
    state: 'Colorado',
    label: '08',
    short: 'CO',
  },
  {
    state: 'Connecticut',
    label: '09',
    short: 'CT',
  },
  {
    state: 'Delaware',
    label: '10',
    short: 'DE',
  },
  {
    state: 'District of Columbia',
    label: '11',
    short: 'DC',
  },
  {
    state: 'Florida',
    label: '12',
    short: 'FL',
  },
  {
    state: 'Georgia',
    label: '13',
    short: 'GA',
  },
  {
    state: 'Hawaii',
    label: '15',
    short: 'HI',
  },
  {
    state: 'Idaho',
    label: '16',
    short: 'ID',
  },
  {
    state: 'Illinois',
    label: '17',
    short: 'IL',
  },
  {
    state: 'Indiana',
    label: '18',
    short: 'IN',
  },
  {
    state: 'Iowa',
    label: '19',
    short: 'IA',
  },
  {
    state: 'Kansas',
    label: '20',
    short: 'KS',
  },
  {
    state: 'Kentucky',
    label: '21',
    short: 'KY',
  },
  {
    state: 'Louisiana',
    label: '22',
    short: 'LA',
  },
  {
    state: 'Minnesota',
    label: '27',
    short: 'MN',
  },
  {
    state: 'Mississippi',
    label: '28',
    short: 'MS',
  },
  {
    state: 'Missouri',
    label: '29',
    short: 'MO',
  },
  {
    state: 'Nebraska',
    label: '31',
    short: 'NE',
  },
  {
    state: 'New Hampshire',
    label: '33',
    short: 'NH',
  },
  {
    state: 'New Mexico',
    label: '35',
    short: 'NM',
  },
  {
    state: 'North Dakota',
    label: '38',
    short: 'ND',
  },
  {
    state: 'Oklahoma',
    label: '40',
    short: 'OK',
  },
  {
    state: 'Oregon',
    label: '41',
    short: 'OR',
  },
  {
    state: 'South Carolina',
    label: '45',
    short: 'SC',
  },
  {
    state: 'South Dakota',
    label: '46',
    short: 'SD',
  },
  {
    state: 'Vermont',
    label: '50',
    short: 'VT',
  },
  {
    state: 'Virginia',
    label: '51',
    short: 'VA',
  },
  {
    state: 'West Virginia',
    label: '54',
    short: 'WV',
  },
  {
    state: 'Wyoming',
    label: '56',
    short: 'WY',
  },
];
