import React from 'react';
import PropTypes from 'prop-types';

const Arrow = ({ fill = '#000000', isOpen = false, size = '0.5rem' }) => {
  const arrowStyle = {
    transform: isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)',
    transition: 'transform 0.3s ease',
  };
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={arrowStyle}
    >
      <path d="M4 6L.536 0h6.928L4 6z" fill={fill} />
    </svg>
  );
};

export default Arrow;

Arrow.propTypes = {
  size: PropTypes.string,
  fill: PropTypes.string,
  isOpen: PropTypes.bool,
  customStyle: PropTypes.object,
};
