import backgroundImageLight from '../assets/img/bg/BG_AMX-min.png';
import backgroundImageDark from '../assets/img/homePageBG1.png';
import AMXDark from '../assets/img/app/logoDark.svg';
import AMXBg from '../assets/img/bg/bg_sr.svg';
// import NewAMXLogo from '../assets/img/app/Alphametricx-logo-mark-dark.png';
import NewAMXLogo from '../assets/img/app/Alphametricx-logo.png';
import { allColorGradients } from './graph-colors';

export const theme = {
  light: {
    primary: '#5F39F8', // blue grade
    background: '#FFFFFF', // white
    backgroundHover: '#857ef5', // purple
    text: '#000000', // black
    secondaryText: '#161A34', // black
    darkText: '#161A34',
    secondaryBackground: '#F6F7FB', // white
    backgroundImage: backgroundImageLight,
    logo: NewAMXLogo || AMXDark,
    logoText: '#ffffff', // white
    settingsButtonBackground: '#ffffff',
    backgroundColor: AMXBg,
    tabInactive: '#161A34', // sky blue
    activeStatusBtnBg: 'rgba(97, 212, 166, 0.2)', // sky blue
    inActiveStatusBtnBg: ' rgba(255, 37, 37, 0.10)', // transparent
    activeStatusBtnText: '#00CE75', // positive green
    inActiveStatusBtnText: '#FF2525', // negative red
    borders: '#c3c7d9', // gray bluies
    tableHeaderColor: '#5c5e60', // gray
    closeButton: '#585858', // drak gray
    grey100: '#808080',
    errorPageText: '#585858', //
    shadow: '#e8e8e8', // white cement
    toolTipHrColor: ' #0000000D ', // light gray
    newsLetterBackground: '#eceff3', //
    newsLetterButtonBorder: '#535770', // peach blue
    disabledBtnBackground: '#e8e8e8', // white cement
    primaryBorderRadius: '10px',
    secondaryBorderRadius: '20px',
    graphAxisColor: '#585858', // drak gray
    volumeGraphTitleColor: '#F54A80', // red pink mix
    isIncreasedColor: '#00B929', // bright green
    isDecreasedColor: '#FF2525', // negative red
    isIncreaseBackGroundColor: '#F2FFF3',
    isDecreasedBackGroundColor: '#FFF0F1',
    disabledBtnColor: '#999999', // red mix gray
    tagsBorderColor: '#cf326b', // gradiant pink
    keyValueColor: '#000000', // black
    addItemContainerColor: '#d9d9d9', // cement
    addContentContainerThumbColor: '#a1a1a1', // cement 2
    black3: '#030303', // black
    errorButtonBackground: '#5F39F8', // blue
    customBrandBckgrnd: '#F4516C0D', // red pink
    customIndustryBckgrnd: '#6257E70D', // blue light white mix
    customPeopleBckgrnd: '#08B08F0D', // green sky blue mix
    customBrandLabelColor: '#E93452', // red
    customIndustryLabelColor: '#4336E3', // blue
    customPeopleLabelColor: '#049F81',
    newsletterContentBackGround: ' #f4f5f8', // green sky blue mix
    greyColor: '#d1d5dc', // white grey
    inActiverticalDots: '#5C5E60', // black gray
    negative: '#bf2b2b', // red
    positive: '#096841', // green
    neutral: '#5f72a2', // grey
    multiTagButton: '#EFEFEF',
    pink: '#BE3B93',
    aiText: '#D83665',
    addContent: '#F2EFFF',
    borderGrey: '#e2e2e2',
    overlay: '#00000033',
    disabled: '#C3C7D9',
    prImpactLabel: '#697077',
    shadeBlue: '#0563c1',
    graphColors: { ...allColorGradients },
    newBackGround: '#d7d9eb',
    chatBackground: '#fbfbfb',
    caret: '#9d4fff',
    purple100: '#753EBA',
    purple200: '#8e44ad',
    operator: '#5a42ec',
    keyword: '#be3b93',
    keywordSecondary: '#000',
  },
  dark: {
    primary: '#2E68FF',
    background: '#000000',
    text: '#FFFFFF',
    secondaryText: '#000000',
    secondaryBackground: '#FFFFFF',
    backgroundImage: backgroundImageDark,
    logo: NewAMXLogo || AMXDark,
    logoText: '#ffffff',
    graphColors: { ...allColorGradients },
  },
  fontFamily: 'Inter',
  responsive: {
    mobile: '768px',
    tab: '998px',
  },
};
