import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DashboardSection } from './index.sc';
import DashboardHeaderV2 from '../search-result/dashboard-section/dashboard-header-v2';
import { useParams } from 'react-router-dom';
import CampaignMonitor from './campaign-monitor';
import AuthorImpact from './author-impact';
import Sentiments from './sentiments';
import PRImpact from './pr-impact';
import Congruence from './congruence';
import CampaignPlaceholder from '../../assets/icons/create-dashboard/campaign_monitor.svg';
import AuthorPlaceholder from '../../assets/icons/create-dashboard/author_impact.svg';
import SentimentPlaceholder from '../../assets/icons/create-dashboard/sentiment_by_themes.svg';
import PrPlaceholder from '../../assets/icons/create-dashboard/pr_impact_scale.svg';
import CongruencePlaceholder from '../../assets/icons/create-dashboard/message_congruence.svg';
import SlotPlaceHolder from '../search-result/slot-details/SlotPlaceHolder';
import { FullSlot } from './campaign-monitor/index.sc';
import { authorImpactClassName } from '../../pages/new-dashboard/utils';

const AdvancedDashboard = ({
  resetData,
  activeScreen,
  loader,
  setArticleType,
  advancedWidgetDetails,
  overView = true,
  setClickedPosition,
  articlePosition,
  overviewDashboard,
  resetSelection,
  setResetSelection,
}) => {
  const { dashboardType } = useParams();

  const advancedDashboardConfig = {
    campaign: CampaignMonitor,
    authorimpact: AuthorImpact,
    sentiments: Sentiments,
    primpact: PRImpact,
    congruence: Congruence,
  };

  const placeholderConfig = {
    campaign: CampaignPlaceholder,
    authorimpact: AuthorPlaceholder,
    sentiments: SentimentPlaceholder,
    primpact: PrPlaceholder,
    congruence: CongruencePlaceholder,
  };

  const placeholderTitleConfig = {
    campaign: 'Campaign Monitor',
    authorimpact: 'Author Impact',
    sentiments: 'Sentiment By Themes',
    primpact: 'PR Impact Scale',
    congruence: 'Message Congruence',
  };

  const componentData =
    advancedWidgetDetails[dashboardType || overviewDashboard];

  const handleOnClick = (event, d) => {
    let clickedSide;
    const viewportWidth = window?.innerWidth;
    // Get the horizontal position of the click
    const clickX = event?.clientX;
    // Determine if the click was on the left or right side
    if (clickX < viewportWidth / 2) {
      clickedSide = 'left';
    } else {
      clickedSide = 'right';
    }
    setResetSelection(false);
    resetData && resetData();
    setArticleType((prev) => ({
      ...prev,
      widget: placeholderTitleConfig[dashboardType] || overviewDashboard,
      graphSelection: d.label,
      rawData: componentData,
      inSyndication: false,
      clickedSide,
      otherInfo: {
        d,
        widget: componentData,
        uniqueId: componentData?.customClassName,
        triggerAISummary: componentData?.triggerAISummary,
      },
    }));
    setClickedPosition(clickedSide);
  };
  const getAdvancedDashboard = () => {
    const Component =
      advancedDashboardConfig[dashboardType || overviewDashboard];

    if (Component && componentData?.show) {
      return (
        <Component
          widget={componentData?.data}
          loader={componentData?.isLoading}
          customClassName={componentData?.customClassName}
          dashboardType={dashboardType}
          handleOnClick={(event, d) => {
            handleOnClick(event, d);
          }}
          resetSelection={resetSelection}
          widgetClassName={
            dashboardType === 'authorimpact' && authorImpactClassName
          }
          helperText={componentData?.text}
        />
      );
    } else {
      return (
        <FullSlot className="graph-widget override-padding">
          <SlotPlaceHolder
            body={placeholderConfig[dashboardType || overviewDashboard]}
            title={placeholderTitleConfig[dashboardType] || overviewDashboard}
          />
        </FullSlot>
      );
    }
  };

  return (
    <DashboardSection
      activeScreen={activeScreen}
      className={activeScreen === 'dashboard' ? 'active' : ''}
      articlePosition={articlePosition}
    >
      {overView && <DashboardHeaderV2 />}
      <div>{getAdvancedDashboard()}</div>
    </DashboardSection>
  );
};

AdvancedDashboard.propTypes = {
  activeScreen: PropTypes.string,
  loader: PropTypes.bool,
  setArticleType: PropTypes.func.isRequired,
  advancedWidgetDetails: PropTypes.object.isRequired,
  overView: PropTypes.bool,
  setClickedPosition: PropTypes.func,
  articlePosition: PropTypes.string,
  overviewDashboard: PropTypes.string,
  resetData: PropTypes.func,
  resetSelection: PropTypes.bool,
  setResetSelection: PropTypes.func,
};

export default AdvancedDashboard;
