import React from 'react';

const DownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="6"
      fill="none"
      viewBox="0 0 8 6"
    >
      <path fill="#585858" d="M4 6L.536 0h6.928L4 6z"></path>
    </svg>
  );
};

export default DownIcon;
