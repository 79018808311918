import styled from 'styled-components';

export const InsightButton = styled.button`
  color: var(--primary-8676-ff, #675ef2);

  /* AMX_Style/Txt_13px_Medium */
  font-family: Inter;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; /* 153.846% */
  letter-spacing: unset;
  margin: 0rem;
  display: flex;
  padding: 0.3rem 0.4rem;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.6rem;
  border: 1px solid var(--primary-8676-ff, #d9d9d9);
  background: var(--grey-white, #fff);
  margin-right: 0.5rem;
  cursor: pointer;
`;

export const UserInsightMainWrp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  background: #fff;
  padding: 1.188rem;
  gap: 0.625rem;
  width: calc(75% - 0.375rem);
`;
export const UberTextTitle = styled.p`
  color: ${({ theme }) => theme.text};
  font-size: 0.85rem;
  font-family: Inter;
  font-weight: 600;
  margin: 0;
  padding: 0;
`;

export const UberText = styled.p`
  color: #585858;
  font-size: 0.75rem;
  font-family: Inter;
  font-weight: 400;
  margin: 0;
  padding: 0;
  margin-bottom: 0.75rem;
`;

export const UberButtonHelperText = styled.p`
  color: #161819;
  font-size: 13px;
  font-family: Inter;
  font-weight: 500;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
`;

export const UberSubtitle = styled.p`
  color: ${({ theme }) => theme};
  font-size: 0.81rem;
  font-family: Inter;
  font-weight: 600;
  margin: 0.25rem 0rem 0rem 0rem;
  padding: 0;
  white-space: nowrap;
`;

export const UberInsightWrp = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 0rem 0rem;
`;

export const ContentWrp = styled.div`
  width: 40%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const FadedText = styled.div`
  max-height: 10rem;
  overflow: hidden;
  position: relative;
  color: black; /* Change the color of the text as needed */
`;

export const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2rem; /* Adjust the height of the gradient fade as needed */
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
`;
