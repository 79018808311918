import React, { useEffect, useState } from 'react';
import {
  HeaderWrp,
  HeadingsWrp,
  Titlewpr,
  IconWrp,
  DescrpTxt,
  Contentwpr,
  Labelbox,
  Labelwpr,
  Inputwpr,
} from '../save-source/index.sc';
import Close from '../../assets/icons/Close';
import { useSelector } from 'react-redux';
import { theme } from '../../constants/theme';
import { ErrorTxt } from '../../pages/login/index.sc';
import {
  FooterBoxwpr,
  LeftfootBoxwpr,
  ButtonsContainer,
} from '../custom-drawer/index.sc';
import { Button } from '../button';
import Proptypes from 'prop-types';

const EditTag = ({
  headingDescp,
  heading,
  handleToggle,
  originalTag,
  articleCount,
  onSubmitHandler,
}) => {
  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });
  const [btnTxt, setBtnTxt] = useState('Done');

  const [tagName, setTagName] = useState('');
  const [nameErrorText, setNameErrorText] = useState('');

  useEffect(() => {
    setTagName(originalTag);
  }, [originalTag]);

  const onSubmitForm = (e) => {
    e.preventDefault();
    if (tagName) {
      setNameErrorText('');
      onSubmitHandler({ oldTag: originalTag, newTag: tagName });
    } else {
      setNameErrorText('Tag name is required.');
    }
  };

  return (
    <Contentwpr onSubmit={onSubmitForm}>
      <HeaderWrp>
        <HeadingsWrp>
          <Titlewpr>{heading}</Titlewpr>
          {headingDescp && <DescrpTxt>{headingDescp}</DescrpTxt>}
        </HeadingsWrp>
        <IconWrp onClick={handleToggle}>
          <Close
            width="1.5rem"
            height="1.5rem"
            color={theme[selectedTheme].text}
          />
        </IconWrp>
      </HeaderWrp>
      <Labelbox>
        <span>Rename &quot;{originalTag}&quot; to</span>
        <Labelwpr htmlFor={`Rename "${originalTag}" to`}>
          <Inputwpr
            value={tagName}
            onChange={(e) => {
              setTagName(e.target.value);
              e.target.value.length > 0 && setNameErrorText('');
            }}
            id="tagName"
            placeholder={'Enter Updated Tag'}
            defaultValue={originalTag}
          />
        </Labelwpr>
        <ErrorTxt style={{ marginTop: '1em', marginBottom: '1em' }}>
          {nameErrorText && nameErrorText}
        </ErrorTxt>
        <DescrpTxt>
          You have {articleCount} Articles tagged as {originalTag}
        </DescrpTxt>
      </Labelbox>
      <FooterBoxwpr mt={1.25} style={{ padding: 0 }}>
        <LeftfootBoxwpr></LeftfootBoxwpr>
        <ButtonsContainer>
          <Button
            title={'Cancel'}
            backgroundColor={theme[selectedTheme].background}
            color={theme[selectedTheme].primary}
            onClick={handleToggle}
            border={theme[selectedTheme].primary}
          />
          <Button
            type="submit"
            title={btnTxt}
            backgroundColor={theme[selectedTheme].primary}
          />
        </ButtonsContainer>
      </FooterBoxwpr>
    </Contentwpr>
  );
};
EditTag.propTypes = {
  headingDescp: Proptypes.string,
  heading: Proptypes.string,
  handleToggle: Proptypes.func,
  originalTag: Proptypes.string,
  articleCount: Proptypes.number,
  onSubmitHandler: Proptypes.func,
};

export default EditTag;
