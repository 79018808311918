import styled from 'styled-components';

export const AIInsightsWrp = styled.div`
  width: 100%;
  /* height: ${({ themeHeight }) => (themeHeight ? '36rem' : '25rem')}; */
  border-radius: ${({ theme, popup }) =>
    popup ? '0.75rem 0.75rem 0 0' : theme.primaryBorderRadius};
  padding: 1.5rem;
  /* background-color: #ffffff; */
  background-image: url(${({ theme, bg1 }) => bg1});
  background-size: cover;
  cursor: pointer;
  border: 2px solid ${({ selected }) => (selected ? 'blue' : 'none')};
  z-index: ${({ selected }) => (selected ? 1 : null)};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const AIInsightTxt = styled.div`
  //styleName: Title/Medium;
  font-family: Inter;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 24.2px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${({ theme }) => theme?.background};
`;

export const AIButtonTxtWrp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const AIButtonWrp = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  justify-content: flex-start;
`;

export const AITxtWrp = styled.div`
  //styleName: Label/Large;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.background};
`;

export const AIButtons = styled.button`
  color: var(--primary-8676-ff, #5f39f8);

  /* AMX_Style/Txt_13px_Medium */
  font-family: Inter;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: unset;
  line-height: 1.25rem; /* 153.846% */
  display: flex;
  padding: 0.5rem 0.8125rem;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.5rem;
  border: 1px solid var(--primary-8676-ff, #5f39f8);
  background: var(--grey-white, #fff);
  cursor: pointer;
  &:hover {
    transition: background 0.2s ease;
    background: #857ef5;
    color: #fff;
    border-color: #857ef5;
  }

  &:active {
    background: #524bc2;
  }
`;
