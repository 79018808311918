import React from 'react';
import Proptypes from 'prop-types';
import { LegendBox, LegendContainer, LegendLabel, LegendWrp } from './index.sc';
import { outletBreakDown as colorBox } from '../../graphs/utils/graphConst';

const GraphLegendOutlet = ({ legendData = [] }) => {
  return (
    <LegendWrp>
      {legendData?.map((legend, i) => (
        <LegendContainer key={i}>
          {legend?.value?.map((subLegend, j) => (
            <>
              <LegendBox
                key={j}
                bgColor={legend?.color || colorBox[i]}
                opacity={j}
              ></LegendBox>
              <LegendLabel key={j}>
                {subLegend?.label} : {subLegend?.value?.toLocaleString('en-US')}
                {/* {j !== legend.value.length - 1 ? '' : ''} */}
              </LegendLabel>
            </>
          ))}
        </LegendContainer>
      ))}
    </LegendWrp>
  );
};

export default GraphLegendOutlet;

GraphLegendOutlet.propTypes = {
  legendData: Proptypes.arrayOf(Proptypes.object),
};
