import React from 'react';
import PropTypes from 'prop-types';
import {
  LegendBox,
  LegendContainer,
  LegendLabel,
  LegendWrp,
  LegendBoxWpr,
} from './index.sc';
import { colorBox } from '../../graphs/utils/graphConst';

const GraphLegendV2 = ({ legendData = [], mediaType }) => {
  const getTotalValue = (legendData) => {
    return legendData.reduce((total, legend) => {
      if (typeof legend.value === 'number') {
        return total + legend.value;
      } else {
        return total;
      }
    }, 0);
  };

  function filterData(data, mediaTypes) {
    return data.filter((item) => {
      // Convert item label to lowercase for case-insensitive comparison
      const label = item.label.toLowerCase();

      // If mediaType is 'all', include all items
      if (Array.isArray(mediaTypes) && mediaTypes.includes('all')) {
        return true;
      }

      // Check if mediaTypes is an array and if it includes the specific media type
      if (Array.isArray(mediaTypes)) {
        // Filter only the specified media types
        if (
          [
            'online',
            'print',
            'blogs',
            'forums',
            'reviews',
            'reddit',
            'x (twitter)',
          ].includes(label)
        ) {
          const correctLabel = label === 'x (twitter)' ? 'twitter' : label;
          return mediaTypes.includes(correctLabel);
        }
      }

      // For other labels, do not filter out
      return true;
    });
  }

  return (
    <LegendWrp>
      {filterData(legendData, mediaType)?.map((legend, i) => {
        const formattedValue =
          typeof legend?.value === 'number'
            ? legend?.value.toLocaleString()
            : legend?.value;
        const percentage = (legend?.value / getTotalValue(legendData)) * 100;

        return (
          <LegendContainer key={i}>
            <LegendBoxWpr>
              <LegendBox bgColor={legend?.color || colorBox[i]}></LegendBox>
              <LegendLabel>{legend?.label}</LegendLabel>
            </LegendBoxWpr>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '11rem',
              }}
            >
              <LegendLabel>{`${percentage.toFixed(0)}%`}</LegendLabel>
              <LegendLabel>{formattedValue}</LegendLabel>
            </div>
          </LegendContainer>
        );
      })}
    </LegendWrp>
  );
};

export default GraphLegendV2;

GraphLegendV2.propTypes = {
  legendData: PropTypes.arrayOf(PropTypes.object),
  mediaType: PropTypes.string,
};
