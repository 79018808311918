import React from 'react';
import PropTypes from 'prop-types';

const Results = ({ color = '#1C1B1F' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        d="M3.833 12.167h5.834V10.5H3.833v1.667zm0-3.334h8.334V7.167H3.833v1.666zm0-3.333h8.334V3.833H3.833V5.5zm-1.666 10c-.459 0-.851-.163-1.177-.49a1.605 1.605 0 01-.49-1.177V2.167c0-.459.163-.851.49-1.177C1.316.663 1.708.5 2.167.5h11.666c.459 0 .851.163 1.177.49.327.326.49.718.49 1.177v11.666c0 .459-.163.851-.49 1.177-.326.327-.718.49-1.177.49H2.167zm0-1.667h11.666V2.167H2.167v11.666z"
      ></path>
    </svg>
  );
};

export default Results;

Results.propTypes = {
  color: PropTypes.string,
};
