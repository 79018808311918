import React from 'react';

const AiIcon = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <g clip-path="url(#clip0_39_40512)"> */}
      <g>
        <path
          //   fill-rule="evenodd"
          //   clip-rule="evenodd"
          d="M-2.28857 17.3002H16.2893C14.5288 13.9697 11.0296 11.7002 7.00036 11.7002C2.9711 11.7002 -0.528083 13.9697 -2.28857 17.3002Z"
          fill="#D83665"
        />
        <path
          d="M10.4999 1.2002L10.6117 1.65294C10.7986 2.41022 11.3899 3.00148 12.1472 3.18843L12.5999 3.3002L12.1472 3.41196C11.3899 3.59891 10.7986 4.19018 10.6117 4.94745L10.4999 5.4002L10.3881 4.94745C10.2012 4.19018 9.60992 3.59891 8.85265 3.41196L8.3999 3.3002L8.85265 3.18843C9.60992 3.00148 10.2012 2.41022 10.3881 1.65294L10.4999 1.2002Z"
          fill="#D83665"
        />
        <path
          d="M4.8999 2.6001L5.28886 4.17566C5.4758 4.93293 6.06707 5.5242 6.82434 5.71114L8.3999 6.1001L6.82434 6.48905C6.06707 6.676 5.4758 7.26726 5.28886 8.02453L4.8999 9.6001L4.51095 8.02454C4.324 7.26726 3.73274 6.676 2.97547 6.48905L1.3999 6.1001L2.97547 5.71114C3.73274 5.5242 4.324 4.93293 4.51095 4.17566L4.8999 2.6001Z"
          fill="#D83665"
        />
      </g>
      <defs>
        <clipPath id="clip0_39_40512">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AiIcon;
