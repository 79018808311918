import React from 'react';
import PropTypes from 'prop-types';

export default function DropDownButton({ size, color, isOpen, angle }) {
  const ArrowButtonStyle = {
    transform: isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)',
    transition: 'transform 0.3s ease',
  };
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={ArrowButtonStyle}
    >
      <path
        d="M4 6L.536 0h6.928L4 6z"
        fill={color}

        // stroke-linecap="round"
        // stroke-linejoin="round"
      />
    </svg>
  );
}

DropDownButton.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  isOpen: PropTypes.bool,
  angle: PropTypes.string,
};
