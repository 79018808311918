import styled, { keyframes } from 'styled-components';

export const ContentWrp = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: ${({ margin }) => (margin ? '150px' : '250px')};
  align-items: center;
  justify-content: center;
`;

export const AiLoading = styled.div`
  position: relative;
`;

const pulsateDots = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

export const AiImg = styled.img`
  width: 180px;
  height: 180px;
`;

export const UberSubtitle = styled.p`
  color: ${({ theme }) => theme};
  font-size: 0.81rem;
  font-family: Inter;
  font-weight: 400;
  margin-bottom: 80px;
  padding: 0;
  white-space: nowrap;
`;

export const Dots = styled.span`
  display: inline-block;
  margin-left: 5px;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: #8a8a8a;
  animation: ${pulsateDots} 2s infinite;
  animation-delay: ${(props) => props.delay}s;
`;
