import React from 'react';
import SearchAlphaAIInsights from '../alpha-ai-insights';
import PropTypes from 'prop-types';
import { PopupStyledMarkdown, PopupTxtWpr } from './index.sc';

const SearchAIInsightsPopup = ({
  popup,
  setInsightType,
  insightType,
  data,
  togglePopup,
  setShowAIInsight,
}) => {
  return (
    <div>
      <SearchAlphaAIInsights
        popup={true}
        setInsightType={setInsightType}
        setShowAIInsight={setShowAIInsight}
      />
      <PopupTxtWpr>
        <PopupStyledMarkdown>{data}</PopupStyledMarkdown>
      </PopupTxtWpr>
    </div>
  );
};

export default SearchAIInsightsPopup;

SearchAIInsightsPopup.propTypes = {
  popup: PropTypes.bool,
  setInsightType: PropTypes.func,
  insightType: PropTypes.string,
  data: PropTypes.func,
  togglePopup: PropTypes.func,
  setShowAIInsight: PropTypes.func,
};
