import React, { useEffect, useRef } from 'react';
import { GraphIconBoxwpr, Iconwpr } from './index.sc';
import DownloadIcon from '../../assets/icons/DownloadIcon';
import Share from '../../assets/icons/Share';
import PropTypes from 'prop-types';
import CircularLoading from '../../assets/icons/loading/circularLoading';
import Tooltip from '../icon-tooltip';
import { theme } from '../../constants/theme';
const GraphIconBox = ({
  fixed = true,
  downloadClickFunction,
  downloading,
  handleShowAddToCanvas = () => {},
  isAdvanced = false,
  setIsClickOutside,
  isSearch = false,
}) => {
  const graphRef = useRef();

  const handleClickOutside = (event) => {
    // Check if the click is outside the dropdown container
    if (graphRef.current && !graphRef.current.contains(event.target)) {
      setIsClickOutside && setIsClickOutside(false);
    }
  };

  useEffect(() => {
    // Attach the event listener to the dropdown container when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <GraphIconBoxwpr fixed={fixed} ref={graphRef} isSearch={isSearch}>
      <Tooltip content="Download">
        <Iconwpr onClick={downloadClickFunction}>
          {downloading ? (
            <CircularLoading
              bgColor={isSearch ? theme.light.primary : theme.light.background}
              size="0.25rem"
              width="1rem"
              height="1rem"
            />
          ) : (
            <DownloadIcon
              color={isSearch ? theme.dark.background : theme.light.background}
            />
          )}
        </Iconwpr>
      </Tooltip>

      {/* <Tooltip content="Share">
        <Iconwpr>
          <Share color={isSearch ? '#000000' : '#FFFFFF'} />
        </Iconwpr>
      </Tooltip> */}
      {/* {!isAdvanced && (
        <Tooltip content="Add to Canvas">
          <Iconwpr onClick={handleShowAddToCanvas}>
            <AddCanvasIcon color="white" />
          </Iconwpr>
        </Tooltip>
      )} */}
    </GraphIconBoxwpr>
  );
};

GraphIconBox.propTypes = {
  fixed: PropTypes.bool,
  downloadClickFunction: PropTypes.func,
  downloading: PropTypes.bool,
  handleShowAddToCanvas: PropTypes.func,
  isAdvanced: PropTypes.bool,
  setIsClickOutside: PropTypes.func,
  isSearch: PropTypes.bool,
};
export default GraphIconBox;
