import {
  purpleColorGradients,
  cyanColorGradients,
  magentaColorGradients,
  yellowColorGradients,
  tealColorGradients,
  blueColorGradients,
  orangeColorGradients,
} from '../../constants/graph-colors';

export const colors = [
  {
    color: purpleColorGradients.purple40,
  },
  {
    color: purpleColorGradients.purple50,
  },
  {
    color: purpleColorGradients.purple60,
  },
  {
    color: cyanColorGradients.cyan40,
  },
  {
    color: cyanColorGradients.cyan50,
  },
  {
    color: cyanColorGradients.cyan60,
  },
  {
    color: magentaColorGradients.magenta40,
  },
  {
    color: magentaColorGradients.magenta50,
  },
  {
    color: magentaColorGradients.magenta60,
  },
  {
    color: yellowColorGradients.yellow40,
  },
  {
    color: yellowColorGradients.yellow50,
  },
  {
    color: yellowColorGradients.yellow60,
  },
  {
    color: tealColorGradients.teal40,
  },
  {
    color: tealColorGradients.teal50,
  },
  {
    color: tealColorGradients.teal60,
  },
  {
    color: blueColorGradients.blue40,
  },
  {
    color: blueColorGradients.blue50,
  },
  {
    color: blueColorGradients.blue60,
  },
  {
    color: orangeColorGradients.orange40,
  },
  {
    color: orangeColorGradients.orange50,
  },
  {
    color: orangeColorGradients.orange60,
  },
];
