import React, { useRef, useState } from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import { IconWpr } from '../custom-popup-wrapper/index.sc';
import { VerticleDots } from '../../assets/icons/VerticleDots';
import SimpleReusableDropDown from '../simple-dropdown';
import { magentaColorGradients } from '../../constants/graph-colors';

const Cardwpr = styled.div`
  padding: 0.5rem 0.75rem;
  font-weight: 500;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0.5rem;
  height: 2rem;
  box-sizing: border-box;
`;

const CardTitle = ({ title = 'Title' }) => {
  return <Cardwpr>{title}</Cardwpr>;
};

CardTitle.propTypes = {
  title: Proptypes.string,
};

export default CardTitle;

const ArtcleThemetitle = styled.div`
  padding: 0.38rem 0.625rem 0.27rem;
  border: 1px solid ${({ theme }) => theme.tagsBorderColor};
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  height: 28px;
  /* width: 4.5rem; */
  justify-content: center;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ isActive }) =>
    isActive ? 'white' : magentaColorGradients?.magenta60};
  background-color: ${({ isActive }) =>
    isActive ? magentaColorGradients?.magenta60 : 'white'};
  cursor: pointer;
`;

export const ArticleTheme = ({
  title = 'Title',
  isActive = false,
  onClickVerticalDots,
  onClickTag,
}) => {
  const downloadRef = useRef(null);

  return (
    <ArtcleThemetitle
      style={{ paddingRight: 0 }}
      isActive={isActive}
      onClick={onClickTag}
    >
      {title}{' '}
      <IconWpr
        width={'20px'}
        height={'20px'}
        style={{ marginLeft: '4px', marginRight: '4px' }}
        ref={downloadRef}
      >
        <VerticleDots
          onClickVerticalDots={(e) => {
            e.stopPropagation();
            onClickVerticalDots();
          }}
          hoverBackgroundColor={isActive ? '#D02670' : '#FFF0F7'}
          color={isActive ? 'white' : '#D02670'}
          backgroundColor={isActive ? '#D02670' : 'white'}
        />
      </IconWpr>
    </ArtcleThemetitle>
  );
};

ArticleTheme.propTypes = {
  title: Proptypes.string,
  isActive: Proptypes.bool,
  onClickVerticalDots: Proptypes.func,
  onClickTag: Proptypes.func,
};
