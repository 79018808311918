import styled, { keyframes } from 'styled-components';

const LoadingAnimation = keyframes`
  0% { color: lightgrey; }
  25% { color: #5F39F8; }
  50% { color: lightgrey; }
  75% { color: #5F39F8; }
  100% { color: lightgrey; }
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 3px 4px 3px 4px;
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  width: fit-content;
`;

export const Dot = styled.span`
  display: inline-block;
  animation: ${LoadingAnimation} 1.5s infinite;
  animation-delay: ${({ delay }) => delay}s;
`;
