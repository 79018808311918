import React from 'react';

const Add3 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#E2E2E2"
        d="M7.25 12h1.5V8.75H12v-1.5H8.75V4h-1.5v3.25H4v1.5h3.25V12zm.756 4a7.81 7.81 0 01-3.11-.625 8.064 8.064 0 01-2.552-1.719 8.065 8.065 0 01-1.719-2.551A7.818 7.818 0 010 7.99c0-1.104.208-2.14.625-3.105a8.066 8.066 0 014.27-4.26A7.818 7.818 0 018.01 0a7.75 7.75 0 013.105.625 8.082 8.082 0 014.26 4.265A7.77 7.77 0 0116 7.994a7.81 7.81 0 01-.625 3.11 8.063 8.063 0 01-1.719 2.552 8.08 8.08 0 01-2.546 1.719A7.77 7.77 0 018.006 16z"
      ></path>
    </svg>
  );
};

export default Add3;
