import React from 'react';
import PropTypes from 'prop-types';

const RadioIcon = ({
  width = '25',
  height = '25',
  color = 'white',
  borderColor = '#5C5E60',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.5"
        cy="12.5"
        r="7.75"
        fill={color}
        stroke={borderColor}
        strokeWidth="1.5"
      />
    </svg>
  );
};

RadioIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  borderColor: PropTypes.string,
};

export default RadioIcon;
