import styled from 'styled-components';

export const LegendWrp = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const LegendContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 14px 0px 14px 0px;
  border-bottom: 1px solid #0000001a;
`;
export const LegendBox = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100rem;
  background-color: ${({ bgColor }) => bgColor || '#c3c7d9'};
`;
export const LegendBoxWpr = styled.div`
  gap: 0.5rem;
  width: 10rem;
  height: 100%;
  display: flex;
  align-items: center;
`;
export const LegendLabel = styled.div`
  color: ${({ theme }) => theme?.graphColors?.coolGray60};
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0.5rem; /* 80% */
  letter-spacing: -0.0125rem;
  white-space: nowrap;
  text-transform: capitalize;
`;
