import styled from 'styled-components';
import { coolGrayColorGradients } from '../../../constants/graph-colors';

export const ArticleDetailsWrp = styled.div`
  min-height: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
  margin-top: 16px;
  color: ${coolGrayColorGradients.coolGray60};
  font-size: 0.75rem;
`;

export const ArticleDetailsL = styled.div`
  font-size: 0.75rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.24px;
  padding-bottom: 1rem;
  span {
    font-weight: 600;
    font-size: 0.8rem;
  }
`;
