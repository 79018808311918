import * as d3 from 'd3';
import {
  outletBreakDown as colorBox,
  initialValues,
} from '../utils/graphConst';
import { setUpEvents } from '../utils/graphEvents';
import { formatNumber } from '../utils/graphGrid';
import { calculateMaxCharacters } from '../utils/graphUtils';
import { hexToRgba } from '../utils/hexToRgba';

export const columnRect3D = function columnRect3D() {
  let config = {
    ...initialValues,
    yTotalLabelSpace: 0,
    yTotalLabelSpace: 0,
    yTotalLabelFS: 10,
    yTotalSubLabelFS: 10,
    groupgutterSpace: 2,
    enableTopLabels: false,
    enableRectLabels: false,
    yRectLabelColor: '#000',
    columnBG: '#eee',
    grayLineHeight: 100,
    labelheight: 50,
    grayLineYposition: 0,
    thresholdColor: 'blue',
    thresholdWidth: 6,
    colors: ['blue', 'green', 'yellow'],
    barSpacing: 2,
  };

  const t = d3
    .transition()
    .delay(function (d, i) {
      return i * 3;
    })
    .duration(config.duration);
  // draw the graph here
  function graph(selected) {
    selected.each(function (data) {
      const xWidth = config.xScale.bandwidth() - config.gutterSpace;
      selected
        .selectAll('.bar-group')
        .data(data)
        .join(
          (enter) => {
            enter
              .append('g')
              .attr('class', 'bar-group')
              .attr('transform', (d) => {
                return ` translate(0, ${
                  d[0].value?.length === 1 || d[0].value?.length === 2
                    ? 50
                    : d[0].value?.length === 5
                    ? 0
                    : 20
                })`;
              }); // moves the entire graph
          },
          (update) => update,
          (exit) => {
            exit.remove();
          }
        );

      selected
        .selectAll('.bar-group')
        .selectAll('.bar-group-l1')
        .data((d, i) => {
          return d.map((ele, i) => ({
            ...ele,
            index: i,
            rawData: data,
            parentData: data,
          }));
        })
        .join(
          (enter) => {
            enter
              .append('g')
              .attr('class', 'bar-group-l1')
              .attr('transform', `rotate(90) translate(0, ${-xWidth - 50})`);
          },
          (update) =>
            update.attr(
              'transform',
              `rotate(90) translate(0, ${-xWidth - 50})`
            ),
          (exit) => {
            exit.remove();
          }
        );

      selected
        .selectAll('.bar-group-l1')
        .selectAll('.bar-group-l2')
        .data((d, i) => {
          return d.value.map((ele, i) => ({
            ...ele,
            index: i,
            rawData: data,
            parentData: d,
          }));
        })
        .join(
          (enter) => {
            enter
              .append('g')
              .attr('class', 'bar-group-l2')
              .style('transform', (d, i) => {
                const padding =
                  d.parentData.value?.length === 1 ||
                  d.parentData.value.length === 2
                    ? 120
                    : d.parentData.value.length === 3
                    ? 150
                    : d.parentData.value.length === 4
                    ? 180
                    : 210;
                return `translateX(${
                  i * (padding / d.parentData.value.length)
                }px)`; // reduce or increase spacing between horizontal bars
              });
          },
          (update) =>
            update.style('transform', (d, i) => {
              const padding =
                d.parentData.value?.length === 1 ||
                d.parentData.value.length === 2
                  ? 120
                  : d.parentData.value.length === 3
                  ? 150
                  : d.parentData.value.length === 4
                  ? 180
                  : 210;
              return `translateX(${
                i * (padding / d.parentData.value.length)
              }px)`;
            }),
          (exit) => {
            exit.remove();
          }
        );
      const columnRect3DFunc = function (eleRef) {
        eleRef
          .attr('data-gi', (d) => d.labelIndex)
          .style('fill', (d, i) => {
            return d?.color ?? colorBox[d.parentData.index % colorBox.length];
          })
          .attr('filter', (d, i) => {
            const color =
              d?.color ?? colorBox[d.parentData.index % colorBox.length];
            const rgbaColor = hexToRgba(color, 0.5);
            return `drop-shadow(4px -2px 8px ${rgbaColor})`;
          })
          .style('opacity', (d, i) => {
            return 1 / (i + 0.4);
          })
          .attr('width', (d) => {
            const totalBars = d?.parentData?.parentData?.value?.length;
            return totalBars === 1 || totalBars === 2
              ? 56
              : totalBars === 5
              ? 35
              : 40;
          }) // Maintain the width of bars
          .attr('rx', 4) // Set horizontal corner radius
          .attr('ry', 4);
      };

      const columnRect3DHeightFunc = function (eleRef) {
        eleRef
          .attr('y', (d, i) => {
            return config.yScale(d.accValue);
          })
          .attr('height', (d, i) => {
            const barHeight =
              config.yScale(0) - config.yScale(parseFloat(d.value));
            return barHeight < 0
              ? -1 * barHeight
              : barHeight - config.barSpacing;
          });
      };

      selected
        .selectAll('.bar-group-l2')
        .selectAll('.column-rect-3d')
        .data((d) => {
          let accValue = 0;
          return d.value.map((ele, i) => {
            accValue += ele.value;
            return {
              ...ele,
              accValue,
              l3Index: i,
              rawData: data,
              parentData: d,
            };
          });
        })
        .join(
          (enter) => {
            enter
              .append('rect')
              .attr('class', 'column-rect-3d')
              .call(columnRect3DFunc)
              .call(columnRect3DHeightFunc);
          },
          (update) =>
            update.call(columnRect3DHeightFunc).call(columnRect3DFunc),
          (exit) => {
            exit.attr('height', 0).attr('width', 0).remove();
          }
        );

      const columnRect3DHeightLabel = function (eleRef) {
        eleRef.attr('y', (d, i) => {
          const startValue =
            i === 0
              ? 0
              : d.parentData.value
                  .slice(0, i)
                  .reduce((acc, item) => acc + item.value, 0);
          const segmentHeight =
            config.yScale(0) - config.yScale(parseFloat(d.value));
          const centerY = config.yScale(startValue) - segmentHeight * 0.5;
          return centerY;
        });
      };

      if (config.enableRectLabels) {
        const columnRect3DLabelFunc = function (eleRef) {
          eleRef.call(columnRect3DHeightLabel).html((d) => {
            const yPos = config.yScale(d.accValue);
            const barHeight =
              config.yScale(0) - config.yScale(parseFloat(d.value));

            // Define character increment and minimum bar height to show text
            const characterIncrement = 8; // Increase characters for every 5 units of bar height
            const minBarHeightToShowText = 45; // Minimum bar height to start showing text

            // Calculate the number of characters to show based on bar height
            let numCharactersToShow =
              Math.floor(
                (barHeight - minBarHeightToShowText) / characterIncrement
              ) + 1;

            // Cap the maximum number of characters based on available space
            const rectWidth = xWidth / d.parentData.parentData.value.length - 2;
            const maxCharacters = calculateMaxCharacters(rectWidth, 11); // Adjust this based on your calculation logic

            // Adjust numCharactersToShow to fit within maxCharacters limit
            numCharactersToShow = Math.min(numCharactersToShow, maxCharacters);

            // Generate the label with a full stop if the full text is shown
            let labelToDisplay = `${d.label} ${formatNumber(d.value)}`;
            if (numCharactersToShow < labelToDisplay.length) {
              labelToDisplay = labelToDisplay.substring(0, numCharactersToShow);
              if (numCharactersToShow > 0) {
                labelToDisplay += '...';
              }
            }

            // Check the condition for final positioning adjustment (assuming it relates to text visibility)
            const finalPos =
              yPos + barHeight - (yPos + (11 / 2 || 6) + barHeight / 2);

            const totalBars = d.parentData.parentData.value.length;

            const yValue =
              totalBars === 1 || totalBars === 2
                ? 30
                : totalBars === 5
                ? 23
                : 25;
            // Return the final label to display based on conditions
            return finalPos > (config.fontSize / 2 || 6)
              ? `<text transform="rotate(-90)" y="${yValue}" x="-15">${labelToDisplay}</text>`
              : '';
          });
        };
        selected
          .selectAll('.bar-group-l2')
          .selectAll('.column-rect-3d-label')
          .data((d) => {
            let accValue = 0;
            return d.value.map((ele, i) => {
              accValue += ele.value;
              return {
                ...ele,
                accValue,
                l3Index: i,
                rawData: data,
                parentData: d,
              };
            });
          })
          .join(
            (enter) => {
              enter
                .append('svg')
                .attr('class', 'column-rect-3d-label')
                .style('text-anchor', 'start')
                .style('pointer-events', 'none')
                .style('font-size', '11px')
                .style('font-weight', 500)
                .style('fill', 'white')
                .call(columnRect3DLabelFunc);
            },
            (update) => update.call(columnRect3DLabelFunc),
            (exit) => {
              exit.remove();
            }
          );
      }

      const getOutletValues = (data) => {
        const outletsData = data[0][0]?.value; // Access the array under the first item in the data
        return outletsData.map((outlet) => {
          const totalValue = outlet.value.reduce(
            (acc, item) => acc + item.value,
            0
          );
          return {
            outlet:
              outlet.label.charAt(0).toUpperCase() + outlet.label.slice(1),
            value: totalValue,
          };
        });
      };

      // Assuming your data is stored in a variable named 'data'
      const outletValuesData = getOutletValues(data);
      const addOutletLabels = function (selected) {
        const outlets = outletValuesData.map((d) => d.outlet);

        // Calculate the maximum width of the outlet labels for alignment
        const maxLabelWidth =
          Math.max(...outlets.map((outlet) => outlet.length)) * 6; // 6 is an approximate width per character, adjust if needed

        selected
          .selectAll('.outlet-label-group')
          .data(outletValuesData, (d) => d.outlet)
          .join(
            (enter) => {
              const labelGroup = enter
                .append('g')
                .attr('class', 'outlet-label-group');

              labelGroup
                .append('text')
                .attr('class', 'outlet-label')
                .attr('x', maxLabelWidth)
                .attr('y', 20)
                .attr('text-anchor', 'end')
                .style('fill', '#6B727C')
                .style('font-size', '11px')
                .style('font-weight', 500)
                .text((d) => d.outlet);

              labelGroup
                .append('text')
                .attr('class', 'outlet-value')
                .attr('x', maxLabelWidth)
                .attr('y', 37)
                .attr('text-anchor', 'end')
                .style('fill', '#000')
                .style('font-size', '13px')
                .style('font-weight', 600)
                .text((d) => formatNumber(d.value));

              return labelGroup;
            },
            (update) => {
              update.select('.outlet-label').text((d) => d.outlet);
              update.select('.outlet-value').text((d) => formatNumber(d.value));
              return update;
            }
          )
          .attr('transform', (d, i) => {
            const totalBars = data[0][0].value?.length;
            const yValue =
              totalBars === 5
                ? [5, 30, 5]
                : totalBars === 4
                ? [15, 42]
                : totalBars === 3
                ? [25, 40]
                : [55, 56];
            if (totalBars === 5) {
              const y = `${[2] * i * i + yValue[1] * i + yValue[0]}`;
              return `translate(-20, ${i === 0 ? 0 : y})`;
            }
            return `translate(-20, ${yValue[0] + i * yValue[1]})`;
          })
          .transition()
          .duration(500)
          .style('opacity', (d, i) => {
            return 1;
          });
      };

      if (config.showOutletLabels) {
        addOutletLabels(selected);
      }
    });

    setUpEvents(config, selected, 'column-rect-3d');

    return selected;
  }

  graph.config = function graphConfig(val) {
    if (!arguments.length) {
      return config;
    }
    config = Object.assign(config, val);
    return graph;
  };

  return graph;
};
