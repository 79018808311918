import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background};
  &.one-d {
    padding: ${({ padding }) => padding ?? '0.6rem 0.6rem'};
    gap: ${({ totalArticleAVEREACH }) => totalArticleAVEREACH && '0.5rem'};
  }
  &.two-d {
    width: fit-content;
    /* min-width: 14.625rem; */
    height: fit-content;
    flex-shrink: 0;
    padding: 0.625rem;
    gap: 0.5rem;
  }
`;
export const TooltipTitle = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.5rem;
  color: ${({ labelColor }) => labelColor || '#4D5358'};
  /* text-transform: capitalize; */
  &.two-d {
    color: ${({ labelColor }) => labelColor || '#000'};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const TooltipBodyWrp = styled.div`
  display: flex;
  flex-direction: ${({ totalArticleAVEREACH }) =>
    totalArticleAVEREACH ? 'column' : 'row'};
  gap: ${({ totalArticleAVEREACH }) =>
    totalArticleAVEREACH ? '0.5rem' : '1rem'};
`;
export const TooltipBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`;
export const TooltipBodyLabel = styled.div`
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '0rem'};
  color: ${({ theme }) => theme?.graphColors?.coolGray70};
  font-weight: 600;
`;

// 2-d
export const TooltipTitleValue = styled.div`
  color: #000;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const TooltipSubTitle = styled.div`
  color: #585858;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const TooltipSubTitleValue = styled.div`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const TooltipLegendWrp = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;
export const TooltipLegendContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`;
export const LegendColor = styled.div`
  width: 1.25rem;
  height: 0rem;
  border: ${({ bgColor }) => `3px solid ${bgColor || '#c3c7d9'}`};
`;
export const LegendLabel = styled.div`
  color: ${({ bgColor }) => bgColor || '#c3c7d9'};
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0.875rem;
`;
export const LegendValue = styled.div`
  color: #000;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Separator = styled.span`
  color: ${({ theme }) => theme?.graphColors?.coolGray20};
  font-weight: 500;
`;

export const ArticlesCount = styled.span`
  color: ${({ theme }) => theme?.graphColors?.coolGray80};
  font-weight: 500;
  font-size: 11px;
`;
